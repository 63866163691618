
export default {
  props: [ 'ballotpediaLabel',
           'ballotpediaURL',
           'registered',
           'registeredLabel',
           'findballot',
           'findballotLabel',
           'returnballot',
           'returnballotLabel',
           'countballot',
           'countballotLabel'
  ],
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    }
  }
}
