import { render, staticRenderFns } from "./start-fpca.vue?vue&type=template&id=0536671e"
import script from "./start-fpca.vue?vue&type=script&lang=js"
export * from "./start-fpca.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {VfaScrollUp: require('/opt/build/repo/components/VfaScrollUp.vue').default,At: require('/opt/build/repo/components/At.js').default,VoterResourceButtons: require('/opt/build/repo/components/VoterResourceButtons.vue').default})
